import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/components/render-from-template-context.js");
